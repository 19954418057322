require("@rails/activestorage").start()
require("@rails/ujs").start()
import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;

import select2 from 'select2';
select2($);
import 'select2/dist/css/select2.css';  // Import Select2 CSS as well

import '@fullcalendar/core'
import '@fullcalendar/daygrid'
import '@fullcalendar/timegrid'
import '@fullcalendar/list'
import '@fullcalendar/moment-timezone'

import 'moment'
import 'moment-timezone'
// import '@popperjs/core'; // Import Popper.js
import 'popper.js'; // Import Popper.js
import 'bootstrap';

console.log('%cWelcome to Thrive', 'background: #83cdd9; color: white; padding: 25px 50px; border-radius: 5px; font-size: 2em;')

// User Time Zone
import jstz from 'jstz'
const timezone = jstz.determine();

function setCookie(name, value) {
  var expires = new Date()
  expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000))
  document.cookie = name + '=' + value + ';expires=' + expires.toUTCString()
}

setCookie("timezone", timezone.name());
$('#global_impact_total_gift').select2();
